import React from "react"
import { Link, graphql } from 'gatsby'
import styles from '../styles/index.module.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faDotCircle, faBone } from '@fortawesome/free-solid-svg-icons'

library.add(faDotCircle, faBone)

export default ({data}) => {
 return (
      <div className={styles.content}>
        {data.allMarkdownRemark.edges.map(({node}) => (
          <div>
          <Link to={node.frontmatter.path} className={styles.postLink}>
            <div className={styles.post} key={node.id}>
              <div className={styles.post_title}>{node.frontmatter.title}</div>
              <div className={styles.post_excerp + " lead"}>{node.excerpt}</div>
            </div>
          </Link>
          <div className={styles.post_footer}>
            <span className={styles.postDate}>
              Posted on <span className="font-bold">{node.frontmatter.date}</span>
            </span>
            <span className={styles.tagsGroup}>
              {node.fields.tags.map(tag => (
               <Link 
                  to={`/tags/${tag}`}
                  className={styles.postFooterTag}
                >
                {tag}
               </Link>
              ))}
            </span>
          </div>
          </div>
        ))}
      </div>
 );
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            path
            description
            date
          }
          fields {
            tags
          }
          excerpt(pruneLength: 400)
        }
      }
    }
  }
`